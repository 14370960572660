<template>
  <v-navigation-drawer permanent :rail="$vuetify.display.mobile ? true : false">
    <v-list>
      <v-list-item
        prepend-icon="$calendar"
        data-cy="calendarSettings"
        to="/app/settings/calendar"
        :active="calendarSettingsActive"
      >
        <v-list-item-title>Calendrier</v-list-item-title>
      </v-list-item>
      <v-list-item
        prepend-icon="$event"
        data-cy="eventSettings"
        to="/app/settings/events"
      >
        <v-list-item-title>Evénements</v-list-item-title>
      </v-list-item>
      <v-list-item
        prepend-icon="$location"
        data-cy="locationSettings"
        to="/app/settings/locations"
      >
        <v-list-item-title>Lieux</v-list-item-title>
      </v-list-item>
      <v-list-item
        data-cy="profilSettings"
        prepend-icon="$account"
        to="/app/settings/profil"
      >
        <v-list-item-title>Profil</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script setup>
import { onMounted, watch, ref } from 'vue';

import log from 'loglevel';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

let calendarSettingsActive = ref(false);

watch(router.currentRoute, (to) => {
  log.info('SettingsMainDialog watch', to);
  if (to.path === '/app/settings' || to.path === '/app/settings/calendar') {
    calendarSettingsActive.value = true;
  } else {
    calendarSettingsActive.value = false;
  }
});

onMounted(() => {
  log.info('SettingsMainDialog mounted', router.currentRoute.value.path);
  if (
    router.currentRoute.value.path === '/app/settings' ||
    router.currentRoute.value.path === '/app/settings/calendar'
  ) {
    calendarSettingsActive.value = true;
  } else {
    calendarSettingsActive.value = false;
  }

  store.commit('setAppNavTitle', 'Paramètres');
});
</script>

<style></style>
